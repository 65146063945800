@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  cursor: auto !important;
} */

.attribution a {
  text-decoration: underline;
}
.attribution a:hover {
  text-decoration: none;
}

#info-modal p {
  margin-bottom: 1em;
}

#info-modal a {
  text-decoration: underline;
  color: #1a5885;
}
#info-modal a:hover {
  color: #2da56f;
}

#info-modal h2 {
  font-weight: bold;
  font-size: 1.6em;
  margin: 1em 0;
}

#info-modal h3 {
  font-weight: bold;
  font-size: 1.2em;
  margin: 1em 0;
}

#info-modal dl {
  display: block;
}

#info-modal dt {
  margin-top: 1em;
  font-weight: bold;
}

#info-modal li {
  list-style-type: disc;
  margin-left: 1em;
}

@layer base {
  html {
    @apply h-full text-community-800;
    background: fixed
        linear-gradient(
          180deg,
          rgba(237, 246, 245, 0) 0%,
          rgba(226, 241, 239, 0.56) 100%
        ),
      #ffffff;
  }

  hr {
    @apply my-10;
  }
}

@layer utilities {
  .text-h1-size {
    @apply font-bold;
    @apply font-serif;
    font-size: 3rem;
    line-height: 1.1125;
  }

  .text-h2-size {
    @apply font-bold;
    @apply font-serif;
    font-size: 2.5rem;
    line-height: 1.3333;
  }

  .text-h3-size {
    @apply font-bold;
    @apply font-sans;
    font-size: 2rem;
    line-height: 1.5;
  }

  .text-h3-size-serif {
    @apply font-bold;
    @apply font-serif;
    font-size: 2rem;
    line-height: 1.5;
  }

  .text-h4-size {
    @apply font-bold;
    @apply font-sans;
    font-size: 1.5rem;
    line-height: 1.5;
  }

  .text-intro-size {
    @apply font-sans;
    font-size: 1.5rem;
    line-height: 1.6;
  }

  .text-p-size {
    line-height: 1.75;
  }

  @media (min-width: 1024px) {
    .text-h1-size {
      font-size: 5rem;
    }

    .text-h2-size {
      font-size: 4rem;
    }

    .text-h3-size {
      font-size: 2.25rem;
    }

    .text-h3-size-serif {
      font-size: 2rem;
    }
  }

  .text-h1 {
    @apply text-h1-size;
    @apply text-community-600;
  }

  .text-h2 {
    @apply text-h2-size;
    @apply text-community-600;
  }

  .text-h3 {
    @apply text-h3-size;
    @apply text-community-700;
  }

  .text-h3-serif {
    @apply text-h3-size-serif;
    @apply text-community-700;
  }

  .text-h4 {
    @apply text-h4-size;
    @apply text-community-700;
  }

  .text-intro {
    @apply text-intro-size;
    @apply text-community-700;
  }

  .text-p {
    @apply text-p-size;
    @apply text-community-800;
  }

  .scroll-snap-none {
    scroll-snap-type: none;
  }
  .scroll-snap-x {
    scroll-snap-type: x mandatory;
  }
  .scroll-snap-y {
    scroll-snap-type: y mandatory;
  }
  .scroll-snap-start {
    scroll-snap-align: start;
  }
  .scroll-snap-end {
    scroll-snap-align: end;
  }
  .mt-header {
    margin-top: 8rem;
  }
  .pt-header {
    padding-top: 8rem;
  }
  .top-header {
    top: 8rem;
  }
  .scroll-mt-header {
    scroll-margin-top: 8rem;
  }
  .scroll-mt-10 {
    scroll-margin-top: 2.5rem;
  }
  .h-screen-header {
    height: calc(100vh - 8rem);
  }
  .w-screen-sidebar {
    width: calc(100vw - 36rem);
  }
  .bg-aclima {
    background: linear-gradient(270deg, #1a5885 0%, #2da56f 100%);
  }
  .bg-aclima-reverse {
    background: linear-gradient(270deg, #1a5885 0%, #2da56f 100%);
  }
  .flex-co2-mobile {
    width: calc((100vw - 36rem - 180px - 180px) * 0.73 + 180px);
  }
  .flex-co2-stationary {
    width: calc((100vw - 36rem - 180px - 180px) * 0.27 + 180px);
  }
  .flex-co-mobile {
    width: calc((100vw - 36rem - 180px - 180px) * 0.93 + 180px);
  }
  .flex-co-stationary {
    width: calc((100vw - 36rem - 180px - 180px) * 0.07 + 180px);
  }
  .flex-nox-mobile {
    width: calc((100vw - 36rem - 180px - 180px) * 0.55 + 180px);
  }
  .flex-nox-stationary {
    width: calc((100vw - 36rem - 180px - 180px) * 0.45 + 180px);
  }
  .flex-pm25-mobile {
    width: calc((100vw - 36rem - 180px - 180px) * 0.17 + 180px);
  }
  .flex-pm25-stationary {
    width: calc((100vw - 36rem - 180px - 180px) * 0.83 + 180px);
  }
  .flex-bc-mobile {
    width: calc((100vw - 36rem - 180px - 180px) * 0.11 + 180px);
  }
  .flex-bc-stationary {
    width: calc((100vw - 36rem - 180px - 180px) * 0.89 + 180px);
  }
  .bg-map-dark {
    background: #141515;
  }
  .bg-chart-1 {
    background: #9ff197;
  }
  .bg-chart-2 {
    background: #ff9300;
  }
  .text-chart-1 {
    color: #9ff197;
  }
  .text-chart-2 {
    color: #ff9300;
  }
  .border-aclima {
    position: relative;
  }
  .border-aclima::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    padding: 1px;
    background: linear-gradient(270deg, #2da570 0%, #2799be 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
  .bg-legend {
    background: #44acab;
    background-image: linear-gradient(
      90deg,
      #9fe8dc,
      #97c696,
      #a39d59,
      #ae723c,
      #ae483f,
      #9c1a54
    );
  }
  .bg-legend-pm25 {
    background: #44acab;
    background-image: linear-gradient(90deg, #fae1d5, #971216);
  }
  .bg-legend-pm25-override {
    background: #44acab;
    background-image: linear-gradient(90deg, #dae1ff, #00438c);
  }
  .bg-legend-no2 {
    background: #44acab;
    background-image: linear-gradient(90deg, #ece1c5, #712600);
  }
  .bg-legend-no2-override {
    background: #44acab;
    background-image: linear-gradient(90deg, #d1ecf5, #004d45);
  }
  .bg-legend-bc {
    background: #44acab;
    background-image: linear-gradient(90deg, #b6bcd5, #020054);
  }
  .bg-legend-co {
    background: #44acab;
    background-image: linear-gradient(90deg, #f8ceea, #590168);
  }
  .bg-legend-co2 {
    background: #44acab;
    background-image: linear-gradient(90deg, #ffd8e5, #8b1d64);
  }
  .bg-legend-o3 {
    background: #44acab;
    background-image: linear-gradient(90deg, #d1ecf5, #004d45);
  }
  .bg-legend-o3-override {
    background: #44acab;
    background-image: linear-gradient(90deg, #e1c8e7, #3d1389);
  }
  .bg-legend-ch4 {
    background: #44acab;
    background-image: linear-gradient(90deg, #e1c8e7, #3d1389);
  }
  .bg-legend-orange {
    background: #44acab;
    background-image: linear-gradient(
      90deg,
      #fff5eb,
      #fee6ce,
      #fdd0a2,
      #fdae6b,
      #fd8d3c,
      #f16913,
      #d94801,
      #a63603,
      #7f2704
    );
  }
  .bg-legend-max-multi {
    background: #44acab;
    background-image: linear-gradient(
      90deg,
      #ffffcc,
      #ffeda0,
      #fed976,
      #feb24c,
      #fd8d3c,
      #fc4e2a,
      #e31a1c,
      #b10026
    );
  }
  .bg-legend-plasma {
    background: #44acab;
    background-image: linear-gradient(
      90deg,
      #0d0887,
      #6a00a8,
      #b12a90,
      #e16462,
      #fca636,
      #f0f921
    );
  }
  .bg-legend-truck-routes {
    background: #969696;
  }
  .rounded-image-top img {
    @apply rounded-t-md;
  }
  .image-blur img {
    filter: blur(3px);
  }
  .icon > div {
    height: 5rem;
    width: 5rem;
    position: relative !important;
  }
  .no-underline-important {
    text-decoration: none !important;
  }
  .table-border-spacing-y-6 {
    border-spacing: 0 0.375rem;
    border-collapse: separate;
  }
}

.slider-thumb-container {
  height: 14rem; width: 1.25rem;
  @apply p-[1.325rem] bg-white rounded-lg shadow-sm z-50;
}

.slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.slider-thumb {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  width: 12.5rem;
  height: 1.25rem;
  transform: translate(-50%, -50%) rotate(-90deg);
  background: transparent;
}

.slider-thumb::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 12.5rem;
  height: 0.7rem;
  @apply bg-gradient-to-r from-pro-200 to-pro-500 rounded-r-md rounded-l-md;
}

.slider-thumb::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 12.5rem;
  height: 0.7rem;
  @apply bg-gradient-to-r from-pro-200 to-pro-500 rounded-r-md rounded-l-md;
}

.slider-thumb::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 12.5rem;
  height: 0.7rem;
  @apply bg-gradient-to-r from-pro-200 to-pro-500 rounded-r-md rounded-l-md;
}

.slider-thumb::-webkit-slider-thumb {
  margin-top: calc(0.5 * (0.7rem - 1.25rem));
  box-sizing: border-box;
  border: none;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  @apply bg-community-500
}
.slider-thumb::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  @apply bg-community-500
}
.slider-thumb::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  @apply bg-community-500
}

.slider-thumb::-ms-tooltip {
  display: none;
}

/* .slider-thumb::-webkit-slider-thumb {
  @apply appearance-none w-5 h-5 rounded-full bg-community-500 cursor-pointer;
} */

.react-select__control {
  border-color: rgba(255, 255, 255, 0.25) !important;
}
.react-select__control:hover {
  border-color: rgba(255, 255, 255, 0.75) !important;
}

.text-ellipsis-children > p {
  overflow: ellipsis;
  max-height: 100%;
}

.important-no-underline {
  text-decoration: none !important;
}

.custom-scrollbar {
  /* TODO: reimplement custom scrollbar used in Brooklyn report */
}

.sticky-table-header thead th {
  position: sticky;
  top: 0;
}

.attribution-grid-child > div {
  @apply gap-2 grid grid-cols-3;
}

/* Hidden in favor of feedback button elsewhere */
.atlwdg-trigger {
  display: none !important;
}

.logo-bg-gradient {
  position: absolute;
  top: -30%;
  left: -30%;
  width: 650px;
  height: 650px;
  background-image: radial-gradient(
      75% 75% at 50% 50%,
      #2d78df4a 0%,
      #194f994a 100%
    ),
    conic-gradient(
      from 349deg at 50% 50%,
      #4fc5b3ff 0%,
      #2fa292ff 15%,
      #2c5681 39%,
      #1f4770ff 61%,
      #356cafff 72%,
      #1f4770ff 79%,
      #4fc5b3ff 100%
    );
  animation: colorRotate 8s alternate infinite linear;
}

.logo-bg-white {
  position: absolute;
  top: -30%;
  left: -30%;
  width: 650px;
  height: 650px;
  background-color: white;
}

@keyframes colorRotate {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
}

@keyframes float {
  from {
    transform: translate(0%);
  }

  to {
    transform: translate(20%);
  }
}

.hs-form {
  @apply overflow-y-auto;
}

.hs_submit {
  @apply p-3  text-white bg-community-500 hover:bg-community-600 rounded w-fit mb-8 transition duration-200 cursor-pointer;
}

.hs-button {
  @apply font-bold cursor-pointer;
}

.hs-main-font-element > h1 {
  @apply font-bold text-public-600 mb-4 text-2xl font-serif;
}

.hs-main-font-element > p {
  @apply prose font-sans;
}

.hs-field-desc {
  @apply prose text-sm mb-4;
}

label {
  @apply text-base font-bold;
}

.hs-form-field {
  @apply my-4;
}

.hs-input {
  @apply border rounded w-full py-2 px-3 text-pro-700 leading-tight focus:outline-community-500;
}

.hs-input[type="checkbox"] {
  @apply mr-2 leading-tight focus:outline;
}

.hs-error-msg {
  @apply text-sm font-normal text-error;
}

.grSatellite path {
  stroke: #ffffff;
  height: 36px;
  width: 36px;
}

.grSatelliteActive path {
  stroke: #3aa59d;
  height: 36px;
  width: 36px;
}

.aiOutlineLineChart {
  height: 24px;
  width: 24px;
}

.wiSmoke {
  height: 18px;
  width: 18px;
}

.tiArrowForward {
  height: 24px;
  width: 24px;
}
